<template>
  <CForm ref="form" class="needs-validation"   >
    <CRow>
      <CCol>
        <CSelect
            label="Sayfa"
            placeholder="Yeni Sayfa Ekle"
            horizontal
            :options="roles.pages"
            :value.sync="newMenu"
        />
      </CCol>
      <CCol>
        <CSelect
            label="Seviye"
            placeholder="Yeni Rol Seviyesi Ekle"
            horizontal
            :options="roleSecurityLevels"
            :value.sync="newRoles"
        />
      </CCol>
      <CCol col="2">
        <CButton color="success" @click="addMenu"  > Ekle </CButton>
      </CCol>
    </CRow>
    <CRow>
      <p style="margin: .5rem; font-weight: bold; font-style: italic; color: red"> 
        Dikkat! Menü ekleme silme ve Rol düzenleme işleminin tamamlanması için ilgili menüyü aşağıdaki listede gördükten sonra en alttaki KAYDET butonuna
        tıklamalısınız. Aksi takdirde yaptığınız değişiklikleri kaybedebilirsiniz.
      </p>
    </CRow>

    <CDataTable
        :items="data"
        :fields="roleField"
        hover
        class="modalTable"
    >
    <template #securityLevel="{item, index}">
      <td>
          <CSelect
              placeholder="Role Seviyesi"
              horizontal
              :options="roleSecurityLevels"
              @change="setRoleSecurityLevels(index, item.securityLevel)"
              :value.sync="item.securityLevel"
          >
          </CSelect>
      </td>
    </template>
    <template #remove="{item}">
      <td>
        <CButton color="danger" @click="removeMenu(item)">Sil</CButton>
      </td>
    </template>
    </CDataTable>
 </CForm>
</template>

<script>

 export default{
     name: "RoleForm",
     props:{
         validated: Boolean,
         params: Object,
         actionType: String,
     },
     watch:{
         actionType:  function(val){
             if(val == 'create')
                 this.data = []
         }
     },
     computed:{
       roleSecurityLevels: function () {
         let data = [];
         this.$store.getters.roleSecurityLevels.map(rsl => {
           data.push({value: rsl.name, label: rsl.name, id: rsl.id})
         })
         return data
       },

       roles: function(){
          let data = []
          let selectedData = []
          let pages = []

          this.$store.getters.roleMenus.map(parent => {
              parent.children.map(child =>{
                if(child.checked){
                  selectedData.push({id: child.id, text: child.text, parent: child.parent, securityLevel: child.roleSecurityLevel})
                }else{
                  pages.push({
                    label: child.text,
                    value: child.id,
                    id: child.id,
                    text: child.text,
                    parent: child.parent,
                    securityLevel: child.roleSecurityLevel,
                  })
                }
              })

              data.push({
                  parentPage: parent.text,
                  parentId: parent.id,
              })
          })

          data.pages = pages
          this.selectData(selectedData)
          return data
      }
     },
     data: function(){
         return{
           data: [],
           newMenu: null,
           newRoles: 'ADMIN',
           roleField: [
             {key: 'text', label: 'Rol Adı', _style: 'font-size:12px'},
             {key: 'securityLevel', label: 'Rol Seviyesi', _style: 'font-size:12px'},
             {key: 'remove', label: 'Sil', _style: 'font-size:12px'},

           ]
         }
     },
     methods:{
       addMenu: function (){
         if(this.newMenu){
           let menu = this.roles.pages.find(e => e.value == this.newMenu)
           this.data.unshift({id: menu.id, text: menu.text, parent: menu.parent, securityLevel: this.newRoles})
           this.roles.pages.splice(this.roles.pages.findIndex(e => e.value == this.newMenu), 1)
           this.newRoles = 'ADMIN'
         }
       },
       setRoleSecurityLevels: function(index, securityLevel){
        this.data[index].securityLevel = securityLevel
       },
       removeMenu: function (item){
         const index = this.data.indexOf(item)
         if (index > -1) {
           this.data.splice(index, 1)
         }
         this.roles.pages.push({
           label: item.text,
           value: item.id,
           id: item.id,
           text: item.text,
           parent: item.parent,
           securityLevel: item.roleSecurityLevel,
         })
       },
      selectData: function(item){
          this.data = []
          this.data = item;
      }
     }
 }
</script>
<style>
  .multiselect__content-wrapper{
      position: static;
  }
</style>